import { memo, useEffect, useState } from "react";
import { FaCheckCircle, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader, ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import { AppDispatch } from "../../../store";
import { SelectAdmin } from "../../../store/slice/admin";
import {
  Approve_Decline_Request,
  getBusinessRequest,
} from "../../../store/service/adminApi";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import ModalP from "../../../components/ModalP";
import { Fmtd_Date } from "../../../Utils/functions";
import Pagination from "../../../components/Pagination";

export default function WithdrawalRequestsBusiness() {
  const dispatch = useDispatch<AppDispatch>();
  const { business_request, loading, pagination } = useSelector(SelectAdmin);
  const [isLoading, setIsLoading] = useState(false);
  const FetchData = async (params: { page: number } | null) => {
    try {
      await dispatch(getBusinessRequest(params));
    } catch (error) {
      console.log(error);
    }
  };

  const sendRequest = (val: number) => {
    return FetchData({ page: val });
  };

  const handleAction = async (params: Action) => {
    try {
      setIsLoading(true);
      const req = await Approve_Decline_Request(params);
      toast.success(req.message);
      await FetchData(null);
      setIsLoading(false);
    } catch (error) {
      toast.error("Approval Failed");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    FetchData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardLayout>
      <ModalP
        hasCancelButton={false}
        modalOpen={isLoading}
        handleClose={() => {}}
      >
        <div className=" px-10 py-4 ">
          <PulseLoader />
        </div>
      </ModalP>
      <div className=" relative  min-w-0 break-words w-full  ">
        <p className=" text-[20px] md:text-[25px] font-[700] ">Requests</p>
        <div className="dash_webkit block pt-10 w-full overflow-x-auto ">
          <table className=" bg-red-00 w-full md:w-full ">
            <thead className=" mb-7 ">
              <tr className=" text-left  border-b-2  border-[#d7d7d7] ">
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Name
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Amount
                </th>

                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Status
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#d7d7d7]">
              {!loading &&
                business_request.length !== 0 &&
                business_request?.map((request) => (
                  <TableRow
                    key={request?.id}
                    fullname={request?.fullname!}
                    id={request?.id}
                    user_id={request?.userId}
                    amount={Number(request?.profit)}
                    status={request?.status}
                    date={Fmtd_Date(request?.created_at)}
                    onClick={(params: Action) => handleAction(params)}
                  />
                ))}

              {!loading && business_request.length === 0 && (
                <tr>
                  <td className="py-0 text-center " colSpan={6}>
                    <div className="w-full py-10 font-bold bg-main text-white text-sm md:text-[20px] flex justify-center items-center ">
                      You Don't Have Any Record
                    </div>
                  </td>
                </tr>
              )}

              {loading && (
                <tr>
                  <td className="py-6 text-center " colSpan={6}>
                    <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
                      <ScaleLoader />
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td className="pt-6" colSpan={6}>
                  <Pagination
                    currentPage={pagination?.current}
                    totalPages={pagination?.total}
                    sendRequest={sendRequest}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
}

const TableRow = memo(
  ({
    fullname,
    amount,
    status,
    date,
    id,
    user_id,
    onClick,
  }: {
    fullname: string;
    amount: number;
    date: string;
    status: string;
    id: string;
    user_id: string;
    onClick: (params: Action) => void;
  }) => {
    return (
      <tr className={`text-left border-b-2  border-[#d7d7d7]`}>
        <td className="py-5 whitespace-nowrap px-3">{fullname}</td>
        <td className="py-5 whitespace-nowrap px-3">${amount}</td>

        <td className="py-5 whitespace-nowrap px-3">{status}</td>
        <td className="py-5 whitespace-nowrap px-3">{date}</td>
        <td className="py-5 whitespace-nowrap px-3">
          <div className="flex gap-3 items-center ">
            <button
              className="bg-slate-200 border-2 border-green-500 p-3 rounded-full  "
              title="Approve Deposit"
              onClick={() =>
                onClick({ id: user_id, action: "APPROVE", withdrawal_id: id })
              }
            >
              <FaCheckCircle fill="green" />
            </button>
            <button
              className="bg-slate-200 border-2 border-red-500 p-3 rounded-full"
              title="Reject Deposit"
              onClick={() =>
                onClick({ id: user_id, withdrawal_id: id, action: "DECLINE" })
              }
            >
              <FaTimes fill="red" />
            </button>
          </div>
        </td>
      </tr>
    );
  }
);
