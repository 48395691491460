import { AiFillBell, AiFillProfile } from "react-icons/ai";
import { BiAccessibility, BiGlobe, BiMessage } from "react-icons/bi";
import {
  Fa500Px,
  FaAudible,
  FaBuffer,
  FaCoins,
  FaCreativeCommonsPdAlt,
  FaHome,
  FaTelegramPlane,
  FaUserTie,
} from "react-icons/fa";
import { GiMoneyStack, GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { RiCoinsFill } from "react-icons/ri";
import { GrBlog, GrBusinessService } from "react-icons/gr";
import { FaRegFaceGrinBeamSweat, FaUsersLine } from "react-icons/fa6";
import { MdDashboard } from "react-icons/md";
import { DiBootstrap } from "react-icons/di";
export const NavUrls = [
  {
    id: 1,
    name: "Home",
    link: "/",
    auth: false,
  },
  {
    id: 4,
    name: "Services",
    link: "/services",
    auth: false,
  },
  {
    id: 3,
    name: "Packages",
    link: "/packages",
    auth: false,
  },
  {
    id: 7,
    name: "About Us",
    link: "/about",
    auth: false,
  },
  {
    id: 5,
    name: "FAQs",
    link: "/faqs",
    auth: false,
  },
  {
    id: 2,
    name: "Security",
    link: "/security",
    auth: false,
  },
  {
    id: 6,
    name: "Contact",
    link: "/contact",
    auth: false,
  },
];

export const DashboardNavUrls = [
  {
    id: 1,
    name: "Home",
    link: "/launchpad",
    icon: FaHome,
  },
  {
    id: 2,
    name: "Docs",
    link: "/project",
    icon: Fa500Px,
  },
  {
    id: 3,
    name: "Join Our Telegram",
    link: "https://solmana.gitbook.io/whitepaer",
    icon: FaTelegramPlane,
  },
  {
    id: 4,
    name: "Join Solmana",
    link: "/dashboard/referral",
    icon: BiAccessibility,
  },
];

export const DashboardLive = [
  {
    name: "Solmana Dashboard",
    icon: "",
    link: "/Dashboard",
  },
  {
    name: "Solmana Sheres",
    icon: "",
    link: "",
  },
  {
    name: "NFT Mint",
    icon: "",
    link: "",
  },
];

export const LinkItems = [
  {
    name: "Dashboard",
    icon: MdDashboard,
    link: "/dashboard",
    status: true,
  },
  {
    name: "Deposit Funds",
    icon: FaCoins,
    link: "/dashboard/deposit",
    status: true,
  },
  {
    name: "View Deposits",
    icon: GiPayMoney,
    link: "/dashboard/view-deposit",
    status: true,
  },
  {
    name: "Withdraw Profit",
    icon: GiReceiveMoney,
    link: "/dashboard/withdrawal-funds",
    status: true,
  },
  {
    name: "View Withdrawals",
    icon: GiMoneyStack,
    link: "/dashboard/view-withdrawal",
    status: true,
  },
  {
    name: "My Referrals",
    icon: AiFillProfile,
    link: "/dashboard/referrals",
    status: true,
  },
  {
    name: "Profile",
    icon: FaUserTie,
    link: "/dashboard/profile",
    status: true,
  },
  {
    name: "Notifications",
    icon: AiFillBell,
    link: "/dashboard/notifications",
    status: true,
  },
];

export const AdminLinkItems = [
  {
    name: "Dashboard",
    icon: MdDashboard,
    link: "/admin/dashboard",
    status: true,
  },

  {
    name: "All Users",
    icon: FaUsersLine,
    link: "/dashboard/admin/users",
    status: true,
  },
  {
    name: "All Deposits",
    icon: FaBuffer,
    link: "/dashboard/admin/deposits",
    status: true,
  },

  {
    name: "Withdrawal Request",
    icon: FaAudible,
    link: "/dashboard/admin/withdrawals-requests",
    status: true,
  },

  {
    name: "All Withdrawals",
    icon: FaCreativeCommonsPdAlt,
    link: "/dashboard/admin/withdrawals",
    status: true,
  },
  {
    name: "Business Withdrawals",
    icon: GrBusinessService,
    link: "/dashboard/admin/withdrawals-business",
    status: true,
  },
  {
    name: "All Blogs",
    icon: DiBootstrap,
    link: "/dashboard/admin/blogs",
    status: true,
  },
  {
    name: "Create Blog",
    icon: GrBlog,
    link: "/dashboard/admin/blogs/create",
    status: true,
  },
  {
    name: "Crypto Address",
    icon: RiCoinsFill,
    link: "/dashboard/admin/address",
    status: true,
  },
  {
    name: "Profile",
    icon: FaUserTie,
    link: "/dashboard/admin/profile",
    status: true,
  },
  {
    name: "All Referrals",
    icon: FaRegFaceGrinBeamSweat,
    link: "/dashboard/admin/referrals",
    status: true,
  },
  // {
  //   name: "Activities",
  //   icon: FaFileContract,
  //   link: "/dashboard/admin/activity",
  //   status: true,
  // },
  {
    name: "Notifications",
    icon: AiFillBell,
    link: "/dashboard/admin/notifications",
    status: true,
  },
];
