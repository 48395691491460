import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../Http/request";

export const getWithdrawals = createAsyncThunk(
  "withdrawal/withdrawal",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(
        `/withdrawal?page=${params ? params.page : 1}`
      );
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDeposits = createAsyncThunk(
  "deposit/deposit",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(`/deposit?page=${params ? params.page : 1}`);
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const makeDeposits = createAsyncThunk(
  "deposit/deposit",
  async (params: object, thunkAPI) => {
    try {
      const response = await post(`/deposit`, params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const makeWithdrawal = createAsyncThunk(
  "withdrawal/withdrawal",
  async (params: object, thunkAPI) => {
    try {
      const response = await post(`/withdrawal`, params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
