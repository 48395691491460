import { memo, useEffect, useState } from "react";
import { FaCheckCircle, FaExternalLinkAlt, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { PulseLoader, ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import { SelectAdmin } from "../../../store/slice/admin";
import { AppDispatch } from "../../../store";
import { getReferrals } from "../../../store/service/adminApi";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import ModalP from "../../../components/ModalP";
import { Fmtd_Date } from "../../../Utils/functions";
import Pagination from "../../../components/Pagination";

export default function AllReferrals() {
  const dispatch = useDispatch<AppDispatch>();
  const { referals, loading, pagination } = useSelector(SelectAdmin);
  const [isLoading, setIsLoading] = useState(false);

  const FetchData = async (params: { page: number } | null) => {
    try {
      await dispatch(getReferrals(params));
    } catch (error) {
      console.log(error);
    }
  };

  const sendRequest = (val: number) => {
    return FetchData({ page: val });
  };

  useEffect(() => {
    FetchData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardLayout>
      <div className=" relative  min-w-0 break-words w-full  ">
        <ModalP
          hasCancelButton={false}
          modalOpen={isLoading}
          handleClose={() => {}}
        >
          <div className=" px-10 py-4 ">
            <PulseLoader />
          </div>
        </ModalP>
        <p className=" text-[20px] md:text-[25px] font-[700] ">Referrals</p>
        <div className="dash_webkit block pt-10 w-full overflow-x-auto ">
          <table className=" bg-red-00 w-full md:w-full ">
            <thead className=" mb-7 ">
              <tr className=" text-left  border-b-2  border-[#d7d7d7] ">
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Referrer
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Amount
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Active
                </th>

                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#d7d7d7]">
              {!loading &&
                referals.length !== 0 &&
                referals?.map((deposit) => (
                  <TableRow
                    key={deposit?.id}
                    fullname={deposit?.fullname!}
                    amount={Number(deposit?.amount!)}
                    id={deposit?.id}
                    claimed={deposit?.claimed}
                    date={Fmtd_Date(deposit?.created_at)}
                  />
                ))}

              {!loading && referals.length === 0 && (
                <tr>
                  <td className="py-0 text-center " colSpan={6}>
                    <div className="w-full py-10 font-bold bg-main text-white text-[20px] flex justify-center items-center ">
                      You Don't Have Any Record
                    </div>
                  </td>
                </tr>
              )}

              {loading && (
                <tr>
                  <td className="py-6 text-center " colSpan={6}>
                    <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
                      <ScaleLoader />
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td className="pt-6" colSpan={6}>
                  <Pagination
                    currentPage={pagination?.current}
                    totalPages={pagination?.total}
                    sendRequest={sendRequest}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
}

const TableRow = memo(
  ({
    fullname,
    amount,
    claimed,
    date,
    id,
  }: {
    id: string;
    fullname: string;
    amount: number;
    claimed: string;
    date: string;
  }) => {
    return (
      <tr className={`text-left border-b-2  border-[#d7d7d7]`}>
        <td className="py-5 whitespace-nowrap px-3">{fullname}</td>
        <td className="py-5 whitespace-nowrap px-3">${amount}</td>
        <td className="py-5 whitespace-nowrap px-3">
          <p
            className={`w-fit px-3 py-1 text-[13px] rounded-full ${
              claimed === "ACTIVE" ? "bg-green-400" : "bg-red-400 text-white "
            }`}
          >
            {claimed === "ACTIVE" ? "Yes" : "No"}
          </p>
        </td>
        <td className="py-5 whitespace-nowrap px-3">{date}</td>
      </tr>
    );
  }
);
