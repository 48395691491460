import { useEffect, useState } from "react";
import { BiArrowBack, BiCopy } from "react-icons/bi";
import toast from "react-hot-toast";
import { PuffLoader, PulseLoader, ClimbingBoxLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";
import { authConfig } from "../../Utils/constants";
import { copyToClipboard } from "../../Utils/functions";
import { routeObj } from "../../constants/routes";

export default function Invoice() {
  const [loading, setLoading] = useState(true);
  const [open, SetOpen] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  const [invoice_id, setInvoice] = useState("");

  useEffect(() => {
    if (id) {
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds >= 6) {
            setLoading(false);
            clearInterval(intervalId); // Clear interval after reaching 4 seconds
            return prevSeconds; // Return the same value as no further increment is needed
          } else {
            setLoading(true);
            return prevSeconds + 1;
          }
        });
      }, 2000);
      return () => clearInterval(intervalId);
    }
  }, [id]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    // Clean up the effect when the modal is closed
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  if (!id) {
    return (
      <div className="  py-5    ">
        <div className=" w-[200px] bg-red-00 mx-auto  ">
          <img
            src="/images/logo.png"
            alt="logo"
            loading="lazy"
            className=""
            height={"100%"}
            width={"100%"}
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!invoice_id) {
              return;
            }
            navigate(`${routeObj.invoice}${invoice_id}`);
            return setInvoice("");
          }}
          className=" w-[95%] md:w-[50%]   mx-auto mt-[50px]"
        >
          <input
            type="text"
            className=" w-full text-sm outline-none py-2 px-3 border border-primary rounded-md "
            placeholder="Enter your invoice number"
            value={invoice_id}
            onChange={(e) => {
              setInvoice(e.target.value);
            }}
          />
          <button
            type="submit"
            className=" bg-main text-sm text-white px-5 py-2 my-4 rounded-lg block mx-auto "
          >
            Get Invoice
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className=" font-jetbrain bg-[#96C8D3] min-h-[100vh] ">
      {/* <FixedPageModal open={open} closeModal={() => {}}>
        <UploadKyc />
      </FixedPageModal> */}
      {!loading && (id === "x74qz9AFpLdggdg" || id === "kRm3P2bVoJdhhdhhd") && (
        <div className="  py-5    ">
          <div className=" w-[200px] bg-red-00 mx-auto  ">
            <img
              src="/images/logo.png"
              alt="logo"
              loading="lazy"
              className=""
              height={"100%"}
              width={"100%"}
            />
          </div>
          <div className=" w-[95%] md:w-[50%]   mx-auto mt-[50px]">
            <div className="  font-jetbrain rounded-xl text-center text-white bg-rgba py-10 ">
              <h1 className=" font-bold font-jetbrain text-[16px] md:text-[30px] ">
                30% Management Fee
              </h1>
              <h2 className=" text-[14px] md:text-[16px] font-jetbrain py-3 ">
                {authConfig.appName}
              </h2>
              <h2 className=" text-[14px] md:text-[16px] font-jetbrain">
                Transaction Amount:{" "}
                {id === "kRm3P2bVoJdhhdhhd" ? "$1,870" : "$9,230"}
              </h2>
            </div>
            {/* bg-gradient-to-r from-[#d7d546] to-[#b3af2c] */}
            <div className=" bg-rgba py-10  px-5 my-10  w-full rounded-xl">
              <div className="  flex flex-col justify-between md:items-center px-3 md:px-7 bg-red-00 ">
                <p className=" mb-2 font-bold text-left text-white  ">
                  Bitcoin Address{" "}
                </p>
                <div className=" w-[100%] px-8 py-4 md:py-5  bg-white flex justify-between items-center ">
                  <p className="dash_webkit text-[14px] md:text-[16px] overflow-x-scroll w-[80%] md:w-full ">
                    bc1q6p3grt6sjrgc43erwky5p83r0f5u0xjymfdl6v
                  </p>{" "}
                  <BiCopy
                    className=" cursor-pointer "
                    onClick={() => {
                      copyToClipboard(
                        "bc1q6p3grt6sjrgc43erwky5p83r0f5u0xjymfdl6v"
                      );
                      toast.success("Bitcoin address copied");
                    }}
                  />
                </div>
              </div>

              <div className=" my-10 flex flex-col justify-between md:items-center px-3 md:px-7 bg-red-00 ">
                <p className=" mb-2 font-bold text-left text-white  ">
                  USDT(ERC20) Address{" "}
                </p>
                <div className=" w-[100%] px-8 py-4 md:py-5 bg-white flex justify-between items-center ">
                  <p className="dash_webkit text-[14px] md:text-[16px] overflow-x-scroll w-[80%] md:w-full ">
                    0xfd448Eb99C3bf0c2B29179949b77d5F1770E8E5d
                  </p>{" "}
                  <BiCopy
                    className=" cursor-pointer "
                    onClick={() => {
                      copyToClipboard(
                        "0xfd448Eb99C3bf0c2B29179949b77d5F1770E8E5d"
                      );
                      toast.success("Usdt address copied");
                    }}
                  />
                </div>{" "}
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              SetOpen(!open);
              toast.success("Thank You For Investing With Us. ");
              return navigate(routeObj.landing);
            }}
            className=" bg-main text-white px-5 py-3 rounded-lg block mx-auto "
          >
            Payment Made
          </button>
        </div>
      )}

      {!loading && id !== "x74qz9AFpLdggdg" && id !== "kRm3P2bVoJdhhdhhd" && (
        <div className="  py-5    ">
          <div className=" w-[200px] bg-red-00 mx-auto  ">
            <img
              src="/images/logo.png"
              alt="logo"
              loading="lazy"
              className=""
              height={"100%"}
              width={"100%"}
            />
          </div>
          <div className=" w-[95%] md:w-[50%]   mx-auto mt-[50px]">
            <div
              onClick={() => navigate(routeObj.landing)}
              className="flex  items-center text-sm gap-2 "
            >
              <BiArrowBack />
              Back
            </div>
            <div className="  font-jetbrain rounded-xl text-center text-white bg-rgba py-10 ">
              <h1 className=" font-bold text-[16px] md:text-[30px] ">
                Invoice Not Found
              </h1>
            </div>
          </div>
        </div>
      )}

      {loading && (
        <div className=" min-h-[200px] flex justify-center items-center ">
          {seconds < 3 && (
            <div className=" flex flex-col items-center justify-cente ">
              <p> Please hold on for just a moment.</p>
              <ClimbingBoxLoader size={8} color="#093776" />
            </div>
          )}
          {seconds > 3 && seconds <= 5 && (
            <div className=" text-[14px] flex items-center text-sub_main ">
              Fetching wallet address, just a sec!
              <PuffLoader size={5} color="#093776" />
            </div>
          )}
          {seconds > 5 && <ClimbingBoxLoader size={8} color="#093776" />}
        </div>
      )}
    </div>
  );
}
