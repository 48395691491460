export type Notification = {
  users: Array<UserType>;
  deposit: Array<DepositData>;
  withdrawal: Array<WithdrawalData>;
  request: Array<RequestData>;
  business_request: Array<WithdrawalData>;
  blog: Array<BlogData>;
  update: Array<UpdateData>;
  notification: Array<NotificationData>;
  activities: Array<ActivityData>;
  pagination: {
    current: number;
    total: number;
  };
  loading: boolean;
  referals: Array<ReferData>;
};

export const initialState: Notification = {
  users: [],
  request: [],
  business_request: [],
  withdrawal: [],
  deposit: [],
  notification: [],
  activities: [],
  blog: [],
  update: [],
  pagination: { current: 1, total: 1 },
  loading: false,
  referals: [],
};
