import { createElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GiMoneyStack, GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
import { AiFillProfile } from "react-icons/ai";
import { PuffLoader, ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import { useUsersAccount } from "../../../Hooks/useAccountHooks";
import {
  apiWithdrawalAccount,
  businessAccount,
  compoudedAccount,
  DeleteAccount,
  ViewAccount,
} from "../../../store/service/adminApi";
import { routeObj } from "../../../constants/routes";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import ModalP from "../../../components/ModalP";
import { numberFormatter } from "../../../Utils/functions";
import { FormInput, SelectInput } from "../../../components/Input";
import { Button } from "../../../components/Button";

export default function EditAccount() {
  const { id } = useParams();
  const [data, setData] = useState<UsersAccount | null>(null);
  const { account_user } = useUsersAccount(data!, id);
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const [pause, setPause] = useState(false);
  const [activate, setActivate] = useState(false);
  const [withdrawal, setWithdrawal] = useState(false);

  const Info: usersInfo = [
    {
      icon: GiPayMoney,
      title: "User's Deposits",
      data: data?.amount ?? 0,
    },
    {
      icon: GiMoneyStack,
      title: "Total Withdrawals",
      data: data?.amt_withdrawn ?? 0,
    },
    {
      icon: GiTakeMyMoney,
      title: "User's Earning",
      data: data?.total_profit ?? 0,
    },
    {
      icon: AiFillProfile,
      title: "User's Profit",
      data: data?.profit ?? 0,
    },
  ];

  const FetchData = async (id: string) => {
    try {
      setLoading(true);
      const { data } = await ViewAccount(id);
      setData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      FetchData(id);
    }
  }, [id]);

  const delAccount = async () => {
    try {
      setDelLoading(true);
      const resp = await DeleteAccount(id!);
      toast.success(resp.message);
      navigate(routeObj.all_users);
      setDelLoading(false);
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
      setDelLoading(false);
    }
  };

  const pseAccount = async () => {
    try {
      setDelLoading(true);
      const resp = await businessAccount(data?.id!);
      toast.success(resp.message);
      navigate(routeObj.all_users);
      setDelLoading(false);
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
      setDelLoading(false);
    }
  };

  const activateAccount = async () => {
    try {
      setDelLoading(true);
      const resp = await compoudedAccount(data?.id!);
      toast.success(resp.message);
      navigate(routeObj.all_users);
      setDelLoading(false);
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
      setDelLoading(false);
    }
  };

  const wthdrawalAccount = async () => {
    try {
      setDelLoading(true);
      const resp = await apiWithdrawalAccount(data?.id!);
      toast.success(resp.message);
      navigate(routeObj.all_users);
      setDelLoading(false);
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
      setDelLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <ModalP
        modalOpen={active}
        hasCancelButton={false}
        handleClose={() => setActive(!active)}
      >
        <p className=" capitalize ">
          Are You Sure You Want to delete this account
        </p>
        {delLoading ? (
          <div className="flex py-6 justify-center items-center ">
            <ScaleLoader />
          </div>
        ) : (
          <div className=" pt-5 flex justify-center items-center gap-4 ">
            <button
              onClick={() => delAccount()}
              className=" bg-red-500 px-4 py-2 rounded-lg text-sm text-white "
            >
              Delete
            </button>
            <button
              onClick={() => setActive(false)}
              className=" bg-blue-700 px-4 py-2 rounded-lg text-sm text-white  "
            >
              Cancel
            </button>
          </div>
        )}
      </ModalP>
      <ModalP
        modalOpen={pause}
        hasCancelButton={false}
        handleClose={() => setPause(!pause)}
      >
        <p className=" capitalize ">Change to Business Account</p>
        {delLoading ? (
          <div className="flex py-6 justify-center items-center ">
            <ScaleLoader />
          </div>
        ) : (
          <div className=" pt-5 flex justify-center items-center gap-4 ">
            <button
              onClick={() => pseAccount()}
              className=" bg-red-500 px-4 py-2 rounded-lg text-sm text-white "
            >
              Yes
            </button>
            <button
              onClick={() => setPause(false)}
              className=" bg-blue-700 px-4 py-2 rounded-lg text-sm text-white  "
            >
              No
            </button>
          </div>
        )}
      </ModalP>

      <ModalP
        modalOpen={activate}
        hasCancelButton={false}
        handleClose={() => setActivate(!activate)}
      >
        <p className=" capitalize ">Change to Compounded Account</p>
        {delLoading ? (
          <div className="flex py-6 justify-center items-center ">
            <ScaleLoader />
          </div>
        ) : (
          <div className=" pt-5 flex justify-center items-center gap-4 ">
            <button
              onClick={() => activateAccount()}
              className=" bg-red-500 px-4 py-2 rounded-lg text-sm text-white "
            >
              Yes
            </button>
            <button
              onClick={() => setActivate(false)}
              className=" bg-blue-700 px-4 py-2 rounded-lg text-sm text-white  "
            >
              No
            </button>
          </div>
        )}
      </ModalP>

      <ModalP
        modalOpen={withdrawal}
        hasCancelButton={false}
        handleClose={() => setWithdrawal(!withdrawal)}
      >
        <p className=" capitalize ">Change to Withdrawal Account</p>
        {delLoading ? (
          <div className="flex py-6 justify-center items-center ">
            <ScaleLoader />
          </div>
        ) : (
          <div className=" pt-5 flex justify-center items-center gap-4 ">
            <button
              onClick={() => wthdrawalAccount()}
              className=" bg-red-500 px-4 py-2 rounded-lg text-sm text-white "
            >
              Yes
            </button>
            <button
              onClick={() => setWithdrawal(false)}
              className=" bg-blue-700 px-4 py-2 rounded-lg text-sm text-white  "
            >
              No
            </button>
          </div>
        )}
      </ModalP>

      <p className=" text-[18px] md:text-[23px] font-[700] ">User's Account</p>
      <div className="flex flex-col gap-10 md:gap-3 flex-wrap pt-10 md:flex-row justify-between ">
        {Info.map((value, index) => (
          <div
            key={index}
            className=" w-full md:w-[23%] px-5 py-8 rounded-md min-h-[80px] bg-white  "
          >
            {loading && (
              <div className="">
                <PuffLoader size={30} />
              </div>
            )}
            {!loading && (
              <div className="flex gap-5 items-center ">
                <p className=" text-[50px] text-sub_main ">
                  {createElement(value.icon)}
                </p>
                <div className=" dash_webkit flex flex-col  overflow-x-scroll ">
                  <p className="">{value.title}</p>
                  <p className="pt-2 ">
                    {value.title !== "Total Referrals"
                      ? `$${numberFormatter(value.data)}`
                      : value.data}
                  </p>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className=" bg-main text-white text-[20px] font-extrabold py-6 text-center my-10 ">
        Account Form
      </div>
      <form onSubmit={account_user.handleSubmit}>
        <div className=" mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
          <FormInput
            borderShown={false}
            name="amount"
            className=" border-2 rounded-lg "
            marginBottom={false}
            label="Amount"
            type="number"
            error={account_user.errors.amount}
            onBlur={account_user.handleBlur}
            onChange={account_user.handleChange}
            value={account_user.values.amount}
          />
          <FormInput
            borderShown={false}
            name="profit"
            className=" border-2 rounded-lg "
            marginBottom={false}
            label="Profit"
            type="number"
            error={account_user.errors.profit}
            onBlur={account_user.handleBlur}
            onChange={account_user.handleChange}
            value={account_user.values.profit}
          />{" "}
          <FormInput
            borderShown={false}
            name="total_profit"
            className=" border-2 rounded-lg "
            marginBottom={false}
            label="Total Profit"
            type="number"
            error={account_user.errors.total_profit}
            onBlur={account_user.handleBlur}
            onChange={account_user.handleChange}
            value={account_user.values.total_profit}
          />{" "}
          <FormInput
            borderShown={false}
            name="amt_withdrawn"
            className=" border-2 rounded-lg "
            marginBottom={false}
            label="Amount Withdrawn"
            type="number"
            error={account_user.errors.amt_withdrawn}
            onBlur={account_user.handleBlur}
            onChange={account_user.handleChange}
            value={account_user.values.amt_withdrawn}
          />
          <FormInput
            borderShown={false}
            name="todays_profit"
            className=" border-2 rounded-lg "
            marginBottom={false}
            label="Todays Profit"
            type="number"
            error={account_user.errors.todays_profit}
            onBlur={account_user.handleBlur}
            onChange={account_user.handleChange}
            value={account_user.values.todays_profit}
          />
        </div>
        {/* <div className=" mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
          <SelectInput
            name="account_mode"
            className=" outline-none bg-red-500 "
            label="Account Mode"
            onChange={account_user.handleChange}
            onBlur={account_user.handleBlur}
            value={account_user.values.account_mode}
          >
            <option value="WEEKLY">WEEKLY</option>
            <option value="BUSINESS">COMPANY</option>
          </SelectInput>
        </div> */}

        <div className="  mt-10 w-full gap-5 bg-red-00 items-center flex flex-col md:flex-row justify-end ">
          {
            <button
              onClick={() => {
                setPause(true);
              }}
              className="py-3 w-full h-fit md:w-[200px] px-10 bg-yellow-400 text-nowrap border-2 text-primary rounded-lg"
              type="button"
            >
              Set To Businness
            </button>
          }
          <button
            onClick={() => {
              setWithdrawal(true);
            }}
            className="py-3 w-full h-fit md:w-[200px] px-10 bg-purple-400 text-nowrap border-2 text-white rounded-lg"
            type="button"
          >
            Set To Withdrawal
          </button>
          {
            <button
              onClick={() => {
                setActivate(true);
              }}
              className="py-3 w-full h-fit text-sm md:w-[200px] px-10 bg-blue-400 text-nowrap border-2 text-white rounded-lg"
              type="button"
            >
              Set To Compounded
            </button>
          }
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="py-3 w-full h-fit md:w-[200px] px-10 border-primary border-2 text-primary rounded-lg"
            type="button"
          >
            Back
          </button>
          <button
            onClick={() => {
              setActive(true);
            }}
            className="py-3 w-full text-sm h-fit md:w-[200px] px-10 bg-red-500 text-white rounded-lg"
            type="button"
          >
            Delete Account
          </button>
          <Button
            loading={account_user.isSubmitting}
            className="py-3 w-full md:w-[200px] px-10 bg-main text-white rounded-lg"
            type="submit"
          >
            Update
          </Button>
        </div>
      </form>
    </DashboardLayout>
  );
}
