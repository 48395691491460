import { useState } from "react";
import { DashboardSideNavLink } from "./NavLink";
import { AdminLinkItems, LinkItems } from "./routes";
import { TbLogout2 } from "react-icons/tb";
import { useSelector } from "react-redux";
import { SelectAuth } from "../../store/slice/auth";
import useAuthHooks from "../../Hooks/authHooks";

export default function DashboardSideBar() {
  const [width, setWidth] = useState(false);
  const { user } = useSelector(SelectAuth);
  const { Logout } = useAuthHooks();

  return (
    <div
      className={` h-full  relative ${
        width ? "w-20" : "w-full"
      } md:pt-5 relative bg-primary overflow-y-scroll`}
    >
      <div className=" text-[16px]  pb-10   px- bg-red-00 text-white font-normal ">
        <div className="  flex justify-between "> </div>
        <div className="relative flex flex-col md:gap-1 mt-5 md:mt-12 ">
          {user && user.type === "ADMIN"
            ? AdminLinkItems?.map((item, index) => (
                <DashboardSideNavLink key={`Dashboard-${index}`} {...item} />
              ))
            : LinkItems?.filter(
                (item) =>
                  item.name !== "Withdraw Profit" ||
                  user?.status === "business" ||
                  user?.status === "compounded"
              ).map((item, index) => (
                <DashboardSideNavLink key={`Dashboard-${index}`} {...item} />
              ))}
        </div>
      </div>
      <button
        onClick={() => {
          Logout();
        }}
        className=" text-primary bg-[#F8F8F8] fixed  w-full  mt-10 md:rounded-tl-3xl md:rounded-bl-3xl py-3  text bg  text-left flex gap-5 px-5 "
      >
        {" "}
        <div className=" text-[20px] ">
          <TbLogout2 fill="#093776" />
        </div>{" "}
        Signout
      </button>
    </div>
  );
}
