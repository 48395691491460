import { createElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GiMoneyStack, GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
import { AiFillProfile } from "react-icons/ai";
import { PuffLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../store";
import { SelectDashboard } from "../../../store/slice/dashboard";
import { SelectAuth } from "../../../store/slice/auth";
import { dashboardDetails } from "../../../store/service/common";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import { numberFormatter } from "../../../Utils/functions";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { makeWithdrawal } from "../../../store/service/investment";
import { Button } from "../../../components/Button";
import { getUser } from "../../../store/service/auth";

type WithdrawalFormProps = {
  profit: number;
};

type FormValues = {
  amount: number | "";
};

export default function WithdrawFunds() {
  const dispatch = useDispatch<AppDispatch>();
  const { total, loading } = useSelector(SelectDashboard);
  const { user } = useSelector(SelectAuth);
  const location = useLocation();
  const navigation = useNavigate();

  useEffect(() => {
    if (user?.status !== "business" && user?.status !== "compounded") {
      navigation("/dashboard");
    }
  }, [user]);

  // Validation schema
  const validationSchema = Yup.object({
    amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be positive")
      .max(
        total?.current ?? 0,
        `Amount cannot exceed your current profit of ${total?.current ?? 0}`
      ),
  });

  // Form submission handler
  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ) => {
    await dispatch(makeWithdrawal(values)).unwrap();
    await dispatch(dashboardDetails());
    setSubmitting(false);
    resetForm();
  };
  // console.log(user);

  const Info: usersInfo = [
    {
      icon: GiPayMoney,
      title: "Total Deposits",
      data: total.depositTot,
    },
    {
      icon: GiMoneyStack,
      title: "Total Withdrawals",
      data: total.withdrawalTot,
    },
    {
      icon: GiTakeMyMoney,
      title: "Total Earning",
      data: total.profitTot,
    },
    {
      icon: AiFillProfile,
      title: "Current Profit",
      data: total.current,
    },
  ];

  useEffect(() => {
    const init = async () => {
      try {
        await dispatch(dashboardDetails());
        await dispatch(getUser()).unwrap();
      } catch (error) {
        console.log(error);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <DashboardLayout>
      <div className="flex flex-col gap-10 md:gap-3 flex-wrap pt-10 md:flex-row justify-between ">
        {Info.map((value, index) => (
          <div
            key={index}
            className=" w-full md:w-[23%] px-5 py-8 rounded-md min-h-[80px] bg-white  "
          >
            {loading && (
              <div className="">
                <PuffLoader size={30} />
              </div>
            )}
            {!loading && (
              <div className="flex gap-5 items-center ">
                <p className=" text-[50px] text-sub_main ">
                  {createElement(value.icon)}
                </p>
                <div className=" dash_webkit flex flex-col  overflow-x-scroll ">
                  <p className="">{value.title}</p>
                  <p className="pt-2 ">
                    {value.title !== "Total Referrals"
                      ? `$${numberFormatter(value.data)}`
                      : value.data}
                  </p>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="mt-10">
        <i>
          An essential protocol to observe is that every withdrawal request
          should be submitted one week following the prior request for the same
          deposit. Requests that fail to adhere to this timing guideline will be
          overlooked and declined.
        </i>
      </div>
      {/* <p className=" mt-10 text-[16px] md:text-[20px] font-[700] ">
        Withdrawal Form
      </p> */}
      <div className="">
        {user?.status === "compounded" && (
          <div className=" bg-main text-center py-7 px-5 my-6 capitalize font-bold text-white ">
            Account is Currently Set To Compounded Mode. Please Contact Your
            Financial Advisor For Further Information
          </div>
        )}
        {user?.status === "business" && (
          <>
            <div className=" bg-main text-center py-7 px-5 my-6 capitalize font-bold text-white ">
              Account is Currently Set To Business Mode. Request for the amount
              you wish to take from your account
            </div>

            <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded-lg">
              <h2 className="text-2xl font-bold mb-4 text-center">
                Withdrawal Form
              </h2>
              <Formik
                initialValues={{ amount: "" }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="mb-4">
                      <label
                        htmlFor="amount"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Withdrawal Amount
                      </label>
                      <Field
                        type="number"
                        id="amount"
                        name="amount"
                        placeholder="Enter amount"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#06274f] focus:border-transparent"
                      />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="text-sm text-red-500 mt-1"
                      />
                    </div>

                    <Button
                      loading={isSubmitting}
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full bg-main text-white py-2 px-4 rounded-md hover:bg-main focus:outline-none focus:ring-2 focus:ring-[#06274f] focus:ring-offset-2"
                    >
                      {"Withdraw"}
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
}
